<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name"
    :slim="true"
    :rules="rules"
  >
    <OField
      :label="label || $attrs.placeholder"
      :label-for="uid"
      :label-class="!label && $attrs.placeholder ? 'sr-only' : ''"
      :variant="errors && errors[$attrs.name] ? 'danger' : ''"
      :message="errors && errors[$attrs.name] && errors[$attrs.name][0]"
      :root-class="fieldClasses"
      :data-name="$attrs.name"
    >
      <ODropdown
        :id="uid"
        ref="Dropdown"
        :value="value"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        :append-to-body="appendToBody"
        :expanded="appendToBody"
        :required="`${rules.includes('required')}`"
        :disabled="disabled || !options.length"
        :aria-disabled="disabled || !options.length"
        :root-class="rootClass"
        @input="$emit('select', $event)"
        @change="$emit('change', $event)"
        @active-change="onActiveChange"
      >
        <button
          ref="Button"
          slot="trigger"
          slot-scope="{ active }"
          type="button"
          tabindex="0"
          role="combobox"
          :aria-expanded="`${dropdownOpen}`"
          :aria-required="`${rules.includes('required')}`"
          :aria-label="ariaLabel || label || $attrs.placeholder"
          :aria-activedescendant="`${uid}-${indexFocused}`"
          :readonly="disabled || !options.length"
          :aria-disabled="disabled || !options.length"
          :class="[
            classes,
            'ucsc-input',
            {
              'ucsc-input--no-border': !border && !active,
              'ucsc-input--focused': active,
              'ucsc-input--focus': active,
              'ucsc-input--placeholder': !value,
              'ucsc-input--danger':
                errors && errors[$attrs.name] && errors[$attrs.name][0],
              'cursor-not-allowed': !options.length,
            },
          ]"
          @blur="onBlur"
          @focus="$emit('focus')"
          @keydown="onKeydownButton"
        >
          <span class="ucsc-input__inner">
            {{ value ? value[field] : $attrs.placeholder }}
          </span>
          <span v-if="showOptionsLength" class="o-drop__length">
            ({{ options.length }})
          </span>
          <SvgIcon
            :name="active ? 'angle-up-blue' : 'angle-down'"
            class="ml-2 o-drop__trigger-icon"
          />
        </button>
        <ClientOnly>
          <ODropdownItem
            v-if="showPlaceholderOption && $attrs.placeholder"
            class="ucsc-input--placeholder"
            :value="null"
            tabindex="-1"
            role="option"
            :aria-selected="value === null"
          >
            <span>{{ $attrs.placeholder }}</span>
          </ODropdownItem>
          <ODropdownItem
            v-for="(item, index) in options"
            :id="`${uid}-${index}`"
            :key="index"
            :value="item"
            role="option"
            :tabindex="indexFocused === index ? '0' : '-1'"
            :aria-selected="$options.isEqual(value, item)"
            @keydown.native="onKeydown($event, item, index)"
          >
            <span>{{ item[field] }}</span>
            <span class="o-drop__item-radio"></span>
          </ODropdownItem>
        </ClientOnly>
      </ODropdown>
    </OField>
  </ValidationProvider>
</template>

<script>
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import InputDropdownErrorsMixin from '~/mixins/input-dropdown-errors'
import UniqueIdMixin from '~/mixins/unique-id'

function getIndexFromOptions(value, options = []) {
  if (!value) return 0

  return options.findIndex((it) => isEqual(it, value))
}

export default {
  isEqual,
  mixins: [InputDropdownErrorsMixin, UniqueIdMixin],

  inheritAttrs: false,

  props: {
    vid: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    ariaLabel: {
      type: String,
      default: null,
    },

    field: {
      type: String,
      default: 'name',
    },

    fieldClasses: {
      type: String,
      default: '',
    },

    classes: {
      type: String,
      default: '',
    },

    value: {
      type: null,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => {
        return []
      },
    },

    isScrollable: {
      type: Boolean,
      default: true,
    },

    appendToBody: {
      type: Boolean,
      default: false,
    },

    maxHeight: {
      type: String,
      default: '282',
    },

    rootClass: {
      type: String,
      default: '',
    },

    menuActiveClass: {
      type: String,
      default: 'o-drop__menu--active',
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    errors: {
      type: Object,
      default: () => {
        return null
      },
    },

    showPlaceholderOption: {
      type: Boolean,
      default: true,
    },

    showOptionsLength: {
      type: Boolean,
      default: false,
    },

    closeOnBlur: {
      type: Boolean,
      default: false,
    },

    border: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dropdownOpen: false,
      indexFocused: getIndexFromOptions(this.value, this.options),
      searchBuffer: '',
      prevKeyPressed: '',
      keyPressed: '',
      currentCycleIndex: 0,
    }
  },
  watch: {
    value(newValue) {
      this.indexFocused = getIndexFromOptions(newValue, this.options)
    },
  },

  mounted() {
    const listEl = this.$refs.Dropdown.$el.querySelector('.o-drop__menu')
    if (listEl instanceof HTMLElement) {
      listEl.setAttribute('role', 'listbox')
      const options = [...listEl.querySelectorAll('o-drop__item')]
      options.forEach((optionEl) => optionEl.setAttribute('role', 'option'))
    }

    this.findItemInList = debounce(this.findItemInList, 300)
    this.cycleItems = debounce(this.cycleItems, 300)
  },

  methods: {
    getIndexFocusedEl() {
      return document.getElementById(`${this.uid}-${this.indexFocused}`)
    },

    findItemInList() {
      const dropdownRef = this.$refs.Dropdown
      const dropdownMenuRef = dropdownRef.$refs.dropdownMenu

      const matchedItem = Array.from(dropdownMenuRef.children).find((it) => {
        const itLC = it.textContent.toLowerCase()

        return itLC.startsWith(this.searchBuffer)
      })

      this.searchBuffer = ''

      if (!matchedItem) {
        return
      }

      dropdownMenuRef.scrollTop = matchedItem.offsetTop
      const index = Array.from(dropdownMenuRef.children).findIndex((it) => {
        return it === matchedItem
      })

      this.indexFocused = this.showPlaceholderOption ? index - 1 : index
      this.getIndexFocusedEl()?.focus()
    },

    cycleItems() {
      const filteredItems = this.options
        .map((opt, i) => ({ ...opt, index: i }))
        .filter((opt) =>
          opt[this.field].toLowerCase().startsWith(this.prevKeyPressed)
        )

      if (filteredItems.length === 0) return

      if (
        this.currentCycleIndex === filteredItems.length - 1 ||
        this.currentCycleIndex >= filteredItems.length
      ) {
        this.currentCycleIndex = 0
      } else {
        this.currentCycleIndex++
      }

      this.indexFocused = filteredItems[this.currentCycleIndex].index
      document.getElementById(`${this.uid}-${this.indexFocused}`).focus()

      this.searchBuffer = ''
    },

    searchOrCycle(key) {
      if (key.match(/[a-zA-Z]{1}/g).length === 1) {
        this.prevKeyPressed = this.keyPressed
        this.keyPressed = key
        this.$refs.Dropdown.isActive = true
        this.searchBuffer = this.searchBuffer + key
        if (
          this.prevKeyPressed.toLocaleLowerCase() === key.toLocaleLowerCase() &&
          this.searchBuffer.length === 1
        ) {
          this.cycleItems()
        } else {
          this.findItemInList()
        }
      }
    },

    onKeydownButton(event) {
      const openDropdown = () => {
        event.preventDefault()
        this.$refs.Dropdown.isActive = true
      }
      switch (event.key) {
        case 'ArrowDown':
        case 'Space':
        case 'Enter':
          openDropdown()
          break
        case 'ArrowUp':
        case 'Home':
          openDropdown()
          this.indexFocused = 0
          this.getIndexFocusedEl().focus()
          break
        case 'End':
          openDropdown()
          this.indexFocused = this.options.length - 1
          this.getIndexFocusedEl().focus()
          break
        default:
          this.searchOrCycle(event.key)
      }
    },

    onKeydown(event, item, index) {
      const maxIndex = this.options.length - 1
      switch (event.key) {
        case 'ArrowDown':
          event.stopPropagation()
          event.preventDefault()
          this.indexFocused =
            this.indexFocused === maxIndex ? maxIndex : index + 1
          break
        case 'ArrowUp':
          event.preventDefault()
          event.stopPropagation()
          this.indexFocused = this.indexFocused === 0 ? 0 : index - 1
          break
        case 'Home':
          event.preventDefault()
          event.stopPropagation()
          this.indexFocused = 0
          break
        case 'End':
          event.preventDefault()
          event.stopPropagation()
          this.indexFocused = maxIndex
          break
        case ' ':
        case 'Tab':
        case 'Enter':
          event.preventDefault()
          event.stopPropagation()
          this.$emit('select', item)
          this.$refs.Dropdown.isActive = false
          return
        case 'Escape':
          event.preventDefault()
          event.stopPropagation()
          // This hack is required due to the lack of APIs offered by Oruga's Dropdowns.
          if (this.$refs.Dropdown) {
            this.$refs.Dropdown.isActive = false
          }
          this.indexFocused = getIndexFromOptions(this.value, this.options)
          return
        default:
          this.searchOrCycle(event.key)
      }
      this.getIndexFocusedEl()?.focus()
    },

    onActiveChange(active) {
      this.dropdownOpen = active
      if (this.disabled || !this.options.length) {
        return
      }

      const dropdownRef = this.$refs.Dropdown
      const dropdownMenuRef = dropdownRef.$refs.dropdownMenu

      // Focuses the first appropriate element
      if (active) {
        const element = this.getIndexFocusedEl()
        // Next tick needed to trigger focus on child immediately after the menu render
        this.$nextTick(() => {
          element.focus()
          this.$emit('select', this.options[this.indexFocused])
          dropdownMenuRef.scrollTop = element.offsetTop
        })
      } else {
        this.$refs.Button.focus()
      }

      if (!this.appendToBody || !active) {
        return
      }

      const appendParent = dropdownMenuRef.closest('.o-drop--expanded')

      if (!appendParent) {
        return
      }

      appendParent.style.width = `${dropdownRef.$el.offsetWidth - 1}px`
    },

    onBlur() {
      if (!this.closeOnBlur) {
        return
      }

      setTimeout(() => {
        this.$emit('blur')
        // This hack is required due to the lack of APIs offered by Oruga's Dropdowns.
        if (this.$refs.Dropdown) {
          this.$refs.Dropdown.isActive = false
        }
      }, 100)
    },
  },
}
</script>

<style>
@import '~/assets/css/components/field.css';
@import '~/assets/css/components/input.css';
@import '~/assets/css/components/dropdown.css';
</style>
